<template>
  <div class="card" style="padding: 10%">
    <div style="margin-bottom: 30px">
      <h4 style="font-weight: 600">陕西西咸新区服务贸易协会</h4>
      <p style="font-size: 14px;line-height: 20px;"> 陕西西咸新区服务贸易协会Shaanxi Xixian New Aera Association of Trade in
        Services(英文缩写：“SXXXATIS”)是经陕西省西咸新区改革创新发展局同意、陕西省西咸新区行政审批与政务服务局核准登记注册（统一社会信用代码：51610100MJU925023A），是西北首家、具有独立法人地位的非营利社会团体。
      </p>
    </div>
    <div class="p-field">
      <label style="width: 100px" for="name">用户名：</label>
      <InputText style="width: calc(100% - 100px)" v-model="data.name" id="name" type="text"/>
    </div>
    <div class="p-field">
      <label style="width: 100px" for="password">密码：</label>
      <InputText style="width: calc(100% - 100px)" v-model="data.password" id="password" type="text"/>
    </div>
    <div class="p-field">
      <label style="width: 100px" for="photo">电话：</label>
      <InputNumber style="width: calc(100% - 100px)" v-model="data.photo" id="photo" :useGrouping="false"
                   :maxFractionDigits="11"/>
    </div>
    <div class="p-field">
      <label style="width: 100px" for="remake">备注：</label>
      <Textarea style="width: calc(100% - 100px);" v-model="data.remark" id="remake" type="text"/>
    </div>
    <div class="p-field">
      <Button @click="submit" style="margin-left: 100px">提交</Button>
    </div>
  </div>
  <Toast/>
</template>

<script>
export default {
  name: "UserInformation",
  data(){
    return{
      data:{}
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    getData(){
      this.$http.get('/adminUser/getMyInformation').then((res)=>{
        this.data = res.data
      })
    },
    submit(){
      this.$http.post('/adminUser/addOrUpdate',this.data).then(()=>{
        this.$toast.add({severity:'success', summary: '成功', life: 3000});
       this.getData()
      })
    },
  }
}
</script>

<style scoped>

</style>